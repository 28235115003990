<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">انتظام للمعلمين</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            تسجيل الدخول
          </b-card-title>
          <b-card-text class="mb-2">
            تسجيل الدخول بحسابك في انتظام
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="الجوال" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="phone"
                    placeholder="رقم الجوال هنا.."
                    ref="phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">كلمة المرور</label>
                  <a target="_blank" href="https://entzam.com/user/reset">
                    <small>نسيت كلمة المرور؟</small>
                  </a>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="كلمة المرور هنا.."
                      ref="password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                تسجيل الدخول
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 border g">
            ليس لديك حساب؟
            <a href="https://entzam.com/user/register" target="_blank">
              إنشاء حساب جديد
            </a>
          </b-card-text>
          <br />
          <hr />
          <b-card-title title-tag="h4" class="font-weight-bold mb-1">
            تسجيل الدخول كمستخدم فرعي
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation2">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="اسم المستخدم" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="username"
                    placeholder="اسم المستخدم هنا.."
                    ref="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">كلمة المرور</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password2"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password2"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password2"
                      placeholder="كلمة المرور هنا.."
                      ref="password2"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="secondary"
                block
                @click="validationForm2"
              >
                تسجيل الدخول
              </b-button>
            </b-form>
          </validation-observer>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center"></div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
const axios = require("axios");
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      password2: "",
      userEmail: "",
      username: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    if (window.location.href.includes("jwt=")) {
      localStorage.setItem("jwt", window.location.href.split("jwt=")[1]);
      window.location = "/";
    }
  },
  methods: {
    validationForm() {
      var _g = this;
      this.$refs.loginValidation.validate().then((success) => {
        axios
          .post(localStorage.getItem("api") + "/auth/login", {
            phone: this.$refs.phone.value,
            password: this.$refs.password.value,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              localStorage.setItem("jwt", r.data.response);
              window.location = "/";
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.data.response,
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      });
    },
    validationForm2() {
      var _g = this;
      this.$refs.loginValidation2.validate().then((success) => {
        axios
          .post(localStorage.getItem("api") + "/auth/sublogin", {
            username: this.$refs.username.value,
            password: this.$refs.password2.value,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              localStorage.setItem("jwt", r.data.response);
              localStorage.setItem("per", JSON.stringify(r.data.pers));
              window.location = "/";
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.data.response,
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
